// ./
import dataFormElements from './dataform.elements'
import { parseEquipmentsResponse, parseRolesResponse, parseWorkAreasResponse } from './response.parser'
import { parseStackedEquipmentsResponse, parseStackedRolesResponse, parseStackedWorkAreasResponse } from './response.parser'

// Classes
import { JoiManager }  from '@/Classes/Network/JoiManager'
import { DataParsers } from '@/Classes/Responses/DataParsers'
import { ValueTools }  from '@/Classes/Static/ValueTools'
import { VuexTools }   from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader     from '@/Components/Global/BasicHeader/template.vue'
import DataTable       from '@/Components/Global/DataTable/template.vue'
import SideMenu        from '@/Components/Global/SideMenu/template.vue'
import AdminNavigation from '@/Components/Modules/2/AdminNavigation/template.vue'
import PermissionsForm from '@/Components/Modules/2/PermissionsForm/template.vue'
import PermissionsList from '@/Components/Modules/2/PermissionsList/template.vue'
import PopupUserForm   from '@/Components/Modules/2/PopupUserForm/template.vue'

// Components (Refs)
import { BasicHeaderRef }     from '@/Components/Global/BasicHeader/component'
import { DataTableRef }       from '@/Components/Global/DataTable/component'
import { SideMenuRef }        from '@/Components/Global/SideMenu/component'
import { DataFormRef }        from '@/Components/Modules/2/DataForm/component'
import { PermissionsFormRef } from '@/Components/Modules/2/PermissionsForm/component'
import { PopupUserFormRef }   from '@/Components/Modules/2/PopupUserForm/component'

// Constants
import { AppValues }   from '@/Constants/Global/AppValues'
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'
import { Documents }   from '@/Constants/Global/Documents'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module2 }     from '@/Constants/Modules/Module2'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View23 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Modules.View23.NAME,

	components: {
		AdminNavigation,
		BasicHeader,
		DataTable,
		PermissionsForm,
		PermissionsList,
		PopupUserForm,
		SideMenu
	},

	data: function() {
		return {
			arrays: {
				keys: ['Equipments', 'WorkAreas']
			},
			dataForms: {
				['Equipments']: dataFormElements.Equipments,
				['WorkAreas']: dataFormElements.WorkAreas
			},
			sideMenu: {
				items: [
					{ type: 'button', label: 'Equipos', componentType: 'PopupUserForm', componentName: 'Equipments', icon: 'tools', isActive: true },
					{ type: 'button', label: 'Áreas', componentType: 'PopupUserForm', componentName: 'WorkAreas', icon: 'briefcase', isActive: false },
					{ type: 'button', label: 'Roles', componentType: 'PermissionsForm', componentName: 'Roles', icon: 'user-tag', isActive: false }
				]
			},
			states: {
				componentType: 'PopupUserForm',
				componentName: 'Equipments',
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseEquipmentsResponse,
				documentToUpdate: undefined,
				inputButtonKey: undefined,
				isMobile: false,
				showDataTable: true,
				showDataTableNewButton: false,
				showPermissionsForm: false,
				showPermissionsList: false,
				showPopupUserForm: false
			}
		}
	},

	mounted: function() {
		this._initDataTable()
		this._initDataForm()
		this._initPermissions()

		// Llamar a la acción encargada de obtener los Sistemas registrados.
		this._permissionsForm.setSystems(Store.getters.getStoredSystems)

		// Establecer las propiedades para el componente <SideMenu>.
		this._sideMenu.initialize(this.sideMenu)
		this._sideMenuMobile.initialize(this.sideMenu)
		this._sideMenuMobile.setStates<SideMenuRef['states']>({ isMobile: true})
		
		// Componente PopupTable/DataTable
		this._nestedDataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)

		// Component View23/DataTable
		this._dataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, preventDefaultStacked: true })
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		},

		_permissionsForm: function(): PermissionsFormRef {
			return this.$refs.permissionsForm as PermissionsFormRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		},

		_sideMenu: function(): SideMenuRef {
			return this.$refs.sideMenu as SideMenuRef
		},

		_sideMenuMobile: function(): SideMenuRef {
			return this.$refs.sideMenuMobile as SideMenuRef
		}
	},

	methods: {
		_initCommunes: async function(name: string) {
			// Limpiar los arrays para evitar duplicidad de opciones.
			const { _dataForm } = this._popupUserForm
			_dataForm.clearOptions('commune')

			// Generar las opciones de los <Select> de las comunas.
			return Store.dispatch('fetchCommunes', { name }).then((res: any[]) => {
				res.sort()
				res.forEach(x => _dataForm.addOption('commune', {value: x, text: x}))
			})
		},

		_initDataForm: function() {
			const { componentName } = this.states
			const { _dataForm } = this._popupUserForm
			const elements: any = dataFormElements
			_dataForm.initialize(elements[componentName])
		},

		_initDataTable: async function(page = 1, forceRefresh = false) {
			if (forceRefresh) this._dataTable.clearAll()

			// Respuesta de la Acción donde se obtienen los Registros.
			const response = await Store.dispatch(`fetch${ this.states.componentName }ForPage`, { forceRefresh, page })

			// Procesar la información y actualizar el Componente.
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_initPermissions: function() {
			const { componentName } = this.states
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject

			switch (componentName) {
				case 'Equipments':
					this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_EQUIPMENTS?.privileges.write)
					this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_EQUIPMENTS?.privileges.write)
					break
				case 'WorkAreas':
					this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_WORKAREAS?.privileges.write)
					this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_WORKAREAS?.privileges.write)
					break
				case 'Roles':
					this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_ROLES?.privileges.write)
					this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_ROLES?.privileges.write)
					break
			}
		},

		_initRegions: function() {
			// Generar las opciones de los <Select> si existen regiones en la Store.
			const { _dataForm } = this._popupUserForm
			const regions = Store.getters.getStoredRegions

			if (regions !== undefined) {
				// Limpiar los arrays para evitar duplicidad de opciones.
				_dataForm.clearOptions('commune')
				_dataForm.clearOptions('region')
				regions.forEach((x: { region: string }) => _dataForm.addOption('region', { value: x.region, text: x.region }))
			}
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.XLarge })
			this._nestedDataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })
			this._permissionsForm.setStates<PermissionsFormRef['states']>({ isMobile: breakpoint <= Breakpoints.Large })

			this.setStates<View23Ref['states']>({ isMobile: breakpoint <= Breakpoints.Medium, dataTableParser: this._resolveDataTableParser(breakpoint <= Breakpoints.XLarge) })
			this._initDataTable()
			this._updatePopupComponents(true)
		},

		_resolveDataTableParser: function(stackedOrDefault: boolean) {
			const { componentName } = this.states
			switch (componentName) {
				case 'Equipments':
					return stackedOrDefault ? parseStackedEquipmentsResponse : parseEquipmentsResponse
				case 'Roles':
					return stackedOrDefault ? parseStackedRolesResponse : parseRolesResponse
				case 'WorkAreas':
					return stackedOrDefault ? parseStackedWorkAreasResponse : parseWorkAreasResponse
			}
		},

		_resolveEquipmentsPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Equipments.AddEquipment
				: Server.Routes.Equipments.UpdateEquipment
		},

		_resolveRolesPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Roles.AddRole
				: Server.Routes.Roles.UpdateRole
		},

		_resolveWorkAreasPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.WorkAreas.AddWorkArea
				: Server.Routes.WorkAreas.UpdateWorkArea
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Propiedades.
			const { _dataForm } = this._popupUserForm
			const key = this.states.inputButtonKey

			// Validaciones Opcionales según Casos.
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState()

			if (key === 'storageName') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selección Ubicación')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyStorages')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchStoragesForPage', { forceRefresh, page })
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data)
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('code', 'asc')
			}
			else if (key === 'leadername') {
				// Actualizar el titulo al componente 'PopupTable'.
				_dataForm._popupTable.setTitle('Selección Líder')

				// Eliminar los Registros si se fuerza una Actualización.
				if (forceRefresh) Store.commit('destroyUsers')
				
				// Aplicar Registros con Paginación.
				const response = await Store.dispatch('fetchUsersForPage', { forceRefresh, isClient: false, page, type: 'Internal' })
				const SummaryParser = DataParsers.Users.GetSummaryInternalsParser(this._nestedDataTable.states.stacked)
				const { fields, items } = SummaryParser(response.data.filter((x: any) => x._idRole !== Documents.Roles.Kreis.ROOT && x._idRole !== Documents.Roles.Kreis.CLIENT && x.isValid !== false))
				this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

				// Aplicar Orden para Columna especifica.
				this._nestedDataTable.resetEmptyText()
				this._nestedDataTable.sortOrder('rut', 'asc')
			}
		},

		_upsertEquipment: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm
			const { action }           = _dataForm.states
			const isInserting          = action === Component.Actions.INSERT

			// Inputs del DataForm.
			const _idEquipment   = documentToUpdate?._idEquipment
			const _idStorage     = _dataForm.getValue('storageName', '_id')
			const code           = _dataForm.getValue('code')
			const name           = _dataForm.getValue('name')
			const mark           = _dataForm.getValue('mark')
			const groupArticle   = _dataForm.getValue('groupArticle')
			const typeArticle    = _dataForm.getValue('typeArticle')
			const groupEquipment = _dataForm.getValue('groupEquipment')
			const typeEquipment  = _dataForm.getValue('typeEquipment')
			const year           = _dataForm.getValue('year')
			const hourmeter      = _dataForm.getValue('hourmeter')
			const isValid        = _dataForm.getValue('isValid') === 'Sí'

			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idStorage, code, name, mark, groupArticle, typeArticle, groupEquipment, typeEquipment, year, hourmeter
			} : {
				_idEquipment, _idStorage, name, mark, groupArticle, typeArticle, groupEquipment, typeEquipment, year, isValid
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.M23.JoiSchemas.AddEquipment : Module2.M23.JoiSchemas.UpdateEquipment
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear un' : 'Actualizar el' } Equipo`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveEquipmentsPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Registro proveniente como respuesta de la petición.
				const { fields, items, actions } = this.states.dataTableParser(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (action === Component.Actions.INSERT) {
					Store.commit('addEquipment', response.data.body[0])
					this._dataTable.addRow(items[0])
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					Store.commit('updateEquipment', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idEquipment')
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this.onClose()
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		_upsertRole: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { action } = this._permissionsForm.states
			const isInserting = action === Component.Actions.INSERT

			// Datos requeridos para actualizar el Servicio.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const _idRole = documentToUpdate?._idRole
			const name    = this._permissionsForm.states.roleName
			const isValid = this._permissionsForm.states.roleStatus

			// Construir el Array con los Permisos.
			const permissions = this._permissionsForm.permissions.map((x: any) => {
				return { _id: x._idPermission, privileges: { ...x.privileges } }
			})

			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idAdminCompany, name, permissions
			} : {
				_idRole, name, permissions, isValid
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.M23.JoiSchemas.AddRole : Module2.M23.JoiSchemas.UpdateRole
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear un' : 'Actualizar el' } Rol`, result.error)

			// Bloquear los Controles hasta obtener una respuesta
			this._permissionsForm.setStates<PermissionsFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveRolesPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Registro proveniente como respuesta de la petición.
				const {fields, items, actions} = this.states.dataTableParser(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (action === Component.Actions.INSERT) {
					Store.commit('addRole', response.data.body[0])
					this._dataTable.addRow(items[0])
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					Store.commit('updateRole', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idRole')
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')

					// Si el Rol actualizado corresponde al que tiene asignado el usuario actual, actualizar sus permisos en el Store.
					const user = Store.getters.getStoredUser
					const permissions = response.data.body[0].permissions
					if (user.role === name) Store.commit('storeUserPermissions', permissions)
				}

				this._permissionsForm.setStates<PermissionsFormRef['states']>({ isFetching: false })
			}

			this.onPFButtonClick('cancel')
		},

		_upsertWorkArea: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate } = this.states
			const { _dataForm }        = this._popupUserForm
			const { action }           = _dataForm.states
			const isInserting          = action === Component.Actions.INSERT

			// Inputs del DataForm.
			const _idAdminCompany = Store.getters.getStoredUser._idAdminCompany
			const _idWorkArea = documentToUpdate?._idWorkArea
			const _idLeader   = _dataForm.getValue('leadername', '_id')
			const name        = _dataForm.getValue('name')
			const isValid     = _dataForm.getValue('isValid') === 'Sí'

			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idAdminCompany, _idLeader, name
			} : {
				_idWorkArea, _idLeader, name, isValid
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.M23.JoiSchemas.AddWorkArea : Module2.M23.JoiSchemas.UpdateWorkArea
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear una' : 'Actualizar la' } Área de Trabajo`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveWorkAreasPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Registro proveniente como respuesta de la petición.
				const { fields, items, actions } = this.states.dataTableParser(response.data.body)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)

				if (action === Component.Actions.INSERT) {
					Store.commit('addWorkArea', response.data.body[0])
					this._dataTable.addRow(items[0])
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					Store.commit('updateWorkArea', response.data.body[0])
					this._dataTable.updateRow(items[0], '_idWorkArea')
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this.onClose()
				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		onButtonClick: function(componentType: string, componentName: string) {
			this.setStates<View23Ref['states']>({ componentName, componentType })
			this._dataTable.clearSortBy()
			this.setStates<View23Ref['states']>({ dataTableParser: this._resolveDataTableParser(this._dataTable.states.stacked) })

			// Inicialización según componente de Edición.
			if (componentType === 'PopupUserForm') {
				this._initDataForm()
			}
			else if (componentType === 'PermissionsForm') {
				this._permissionsForm.setSystems(Store.getters.getStoredSystems)
			}
			
			// TODO: Componente que pretende Administrar Permisos directamente desde la Vista.
			// if (componentType === 'PermissionsList') {
			// 	this.setStates<View23Ref['states']>({ showDataTable: false, showPermissionsForm: false, showPermissionsList: true })
			// 	return
			// }
				
			// Actualizar los estados para renderizar los componentes correctos.
			this._dataTable.setPaginationPage(1)
			this.setStates<View23Ref['states']>({ showDataTable: true, showDataTableNewButton: componentName !== 'Equipments', showPermissionsForm: false, showPermissionsList: false })
		},

		onClose: function() {
			this.setStates<View23Ref['states']>({ showPopupUserForm: false })
			this._popupUserForm._dataForm.clearInputs()
		},

		onDataFormSubmit: function() {
			const { componentName } = this.states
			if (componentName === 'Equipments') {
				// Registro o Actualización de un Equipo.
				this._upsertEquipment()
			}
			else if (componentName === 'WorkAreas') {
				// Registro o Actualización de una Área de Trabajo.
				this._upsertWorkArea()
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			// Almacenar en la propiedad 'documentToUpdate' la fila a actualizar.
			this.setStates<View23Ref['states']>({ documentToUpdate: row.item })
			const { componentName, documentToUpdate } = this.states

			if (key === 'edit') {
				const { _dataForm } = this._popupUserForm
				
				// Establecer ciertas propiedades a los Formularios especificos.
				if (componentName !== 'Roles') {
					this.setStates<View23Ref['states']>({ dataFormAction: Component.Actions.UPDATE, showPopupUserForm: true })
					_dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })
				}
				else {
					this.setStates<View23Ref['states']>({ showDataTable: false, showPermissionsForm: true })
					this._permissionsForm.setStates<PermissionsFormRef['states']>({ action: Component.Actions.UPDATE })
				}

				// Array con valores no validos para comparar el contenido del campo correspondiente,
				// y evitar establecer valores no deseados en el componente 'DataForm'.
				const invalidValues = [undefined, null, '', AppValues.Strings.DEFAULT_EMPTY_STRING]
				
				// Establecer los parametros en los formularios segun el nombre del componente.
				if (componentName === 'Equipments') {
					// Autocompletar los Inputs al momento de editar.
					for (let $k of ['code', 'mark', 'groupArticle', 'typeArticle', 'groupEquipment', 'typeEquipment', 'year', 'name', 'hourmeter', 'isValid']) {
						const value = documentToUpdate[$k]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue($k, value)
						}
					}

					// Autocompletar los InputsButtons al momento de editar.
					for (let $k of ['storageName']) {
						const id = `_id${$k[0].toUpperCase() + $k.slice(1)}`
						const value = documentToUpdate[$k]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue($k, documentToUpdate[id], '_id')
							_dataForm.setValue($k, value, 'value')
						}
					}
				}
				else if (componentName === 'WorkAreas') {
					// Autocompletar los Inputs al momento de editar.
					for (let $k of ['name', 'isValid']) {
						const value = documentToUpdate[$k]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue($k, value)
						}
					}

					// Autocompletar los InputsButtons al momento de editar.
					for (let $k of ['leadername']) {
						const id = `_id${$k[0].toUpperCase() + $k.slice(1)}`
						const value = documentToUpdate[$k]
						if (!ValueTools.isEqualTo(value, invalidValues)) {
							_dataForm.setValue($k, documentToUpdate[id], '_id')
							_dataForm.setValue($k, value, 'value')
						}
					}
				}
				else if (componentName === 'Roles') {
					this._permissionsForm.setStates<PermissionsFormRef['states']>({ roleId: row.item._idRole, roleStatus: row.item.status === 'Activo', roleName: row.item.name })
					this._permissionsForm.updatePermissionsStatus(row.item.permissions)
				}

				// Ocultar el Indicador de Progreso.
				if (componentName !== 'Roles') this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}
		},

		onDTNewButtonClick: function() {
			const { componentType } = this.states
			if (componentType === 'PopupUserForm') {
				this.setStates<View23Ref['states']>({ dataFormAction: Component.Actions.INSERT, showPopupUserForm: true })
				this._initRegions()
				this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: this.states.dataFormAction })
			}
			else if (componentType === 'PermissionsForm') {
				this.setStates<View23Ref['states']>({ showDataTable: false, showPermissionsForm: true })
				this._permissionsForm.updateAuxPermissionsStatus()
				this._permissionsForm.clearAll()
				this._permissionsForm.setStates<PermissionsFormRef['states']>({ action: Component.Actions.INSERT, isRevertButtonEnabled: false })
			}
		},

		onDTPaginationChanged: async function(page: number) {
			this._initDataTable(page)
		},

		onDTRefreshButtonClick: async function(currentPage: number)  {
			Store.commit(`destroy${ this.states.componentName }`)
			this._initDataTable(currentPage, true)
		},

		onDTSearchButtonClicked: function(searching: any) {
			const { componentName } = this.states
			const { searchKey, searchValue } = searching
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Asignar los parametros por pestaña.
			switch (componentName) {
				case 'Equipments': {
					this._dataTable.doInputSearch(Server.Routes.Equipments.GetEquipmentsBySearchFilter,
						this.states.dataTableParser,
						{ _idAdminCompany, searchKey, searchValue }
					)
					break
				}
				case 'Roles': {
					this._dataTable.doInputSearch(Server.Routes.Roles.GetRolesBySearchFilter,
						this.states.dataTableParser,
						{_idAdminCompany, searchKey, searchValue: (searchValue?.toLowerCase() === AppValues.Strings.TEXT_FRIENDLY_YES?.toLocaleLowerCase()) || searchValue}
					)
					break
				}
				case 'WorkAreas': {
					this._dataTable.doInputSearch(Server.Routes.WorkAreas.GetWorkAreasBySearchFilter,
						this.states.dataTableParser,
						{ _idAdminCompany, searchKey, searchValue: (searchValue?.toLowerCase() === AppValues.Strings.TEXT_FRIENDLY_YES?.toLowerCase()) || searchValue }
					)
					break
				}
			}
		},

		onInputButtonClick: function(key: string)  {
			this.setStates<View23Ref['states']>({ inputButtonKey: key })
			this._updatePopupComponents(false)
		},

		onOptionChange: function(key: string, name: string) {
			if (key === 'region') {
				this._initCommunes(name)
			}
		},

		onPFButtonClick: function(action: string) {
			switch (action) {
				case 'submit':
					return this._upsertRole()
				case 'cancel':
					this._permissionsForm.clearAll()
					return this.setStates<View23Ref['states']>({ showDataTable: true, showPermissionsForm: false })
			}
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { _dataForm } = this._popupUserForm
			if (key === 'storageName') {
				_dataForm.setValue(key, currentRow.item._idStorage, '_id')
				_dataForm.setValue(key, `${ currentRow.item.code } - ${ currentRow.item.name }`, 'value')
			}
			else if (key === 'leadername') {
				_dataForm.setValue(key, currentRow.item._idUser, '_id')
				_dataForm.setValue(key, currentRow.item.name, 'value')
			}
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function(key: string) {
			this._updatePopupComponents(false, 1, true)
		},

		onServerCaughtFetchException: function() {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			this._permissionsForm.setStates<PermissionsFormRef['states']>({ isFetching: false })
		},

		onServerFailedResponse: function() {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			this._permissionsForm.setStates<PermissionsFormRef['states']>({ isFetching: false })
		}
	},

	watch: {
		// ...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initDataTable'),
		// ...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Equipments, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
		// ...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Roles, '_initDataTable'),
		// ...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.WorkAreas, '_initDataTable')
	}
})

// Exports
export default View23
export type View23Ref = InstanceType<typeof View23>