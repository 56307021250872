// Component's Classes
import { InputButton } from '@/Components/Modules/2/DataForm/Classes/InputButton'
import { InputNumber } from '@/Components/Modules/2/DataForm/Classes/InputNumber'
import { InputText }   from '@/Components/Modules/2/DataForm/Classes/InputText'
import { Select }      from '@/Components/Modules/2/DataForm/Classes/Select'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'

// Form Export
export default {
	Equipments: {
		params: {
			currentTab: AppValues.Strings.TEXT_EQUIPMENTS.toLowerCase(),
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: AppValues.Strings.TEXT_EQUIPMENTS.toLowerCase(),
					text: { onInsert: 'Nuevo Equipo', onUpdate: 'Actualizar Equipo' }
				},
				columns: [
					new InputText('code', 'Código').setReadOnly(Component.Actions.INSERT),
					new InputNumber('hourmeter', 'Horometro').setRange(0).setVisible(Component.Actions.INSERT),
					new InputText('serie', 'Serie'),
					new InputText('name', 'Descripción'),
					new InputText('mark', 'Marca'),
					new InputText('groupEquipment', 'Grupo Equipo '),
					new InputText('groupArticle', 'Grupo Artículo'),
					new Select('typeEquipment', 'Tipo Equipo', Object.values(AppValues.EquipmentTypes)),
					new Select('typeArticle', 'Tipo Artículo', Object.values(AppValues.ArticleTypes)),
					new InputButton('storage', 'Ubicación').setIcon('pen'),
					new InputNumber('year', 'Año').setRange(1950, 2030),
					new Select('isValid', '¿Esta Activa?', ['Sí', 'No']).setVisible(Component.Actions.UPDATE)
				]
			}
		]
	},
	WorkAreas: {
		params: {
			currentTab: AppValues.Strings.TEXT_WORKAREAS.toLowerCase(),
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: AppValues.Strings.TEXT_WORKAREAS.toLowerCase(),
					text: { onInsert: 'Nueva Área de Trabajo', onUpdate: 'Actualizar Área de Trabajo' }
				},
				columns: [
					new InputText('name', 'Nombre'),
					new InputButton('leadername', 'Líder').setIcon('pen'),
					new Select('isValid', '¿Esta Activa?', ['Sí', 'No']).setVisible(Component.Actions.UPDATE)
				]
			}
		]
	}
}