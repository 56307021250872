// Dependencies
import Vue from 'vue'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const PermissionsList = Vue.extend({
	name: 'PermissionsList',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				selectedSystem: null
			}
		}
	},

	computed: {
		_getPermissions: function() {
			const permissions = Store.getters.getStoredPermissions
			return permissions || []
		},

		_getSystems: function() {
			const systems = Store.getters.getStoredSystems
			return systems?.map((x: any) => ({ value: x._idSystem, text: x.title })).sort((a: any, b: any) => a.text > b.text ? 1 : -1) || []
		}
	}
})

// Exports
export default PermissionsList
export type PermissionsListRef = InstanceType<typeof PermissionsList>