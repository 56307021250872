// src
import Config from '@/config'

// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

// Store
import Store from '@/Store/Global/Default'

export function parseEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idStorage', '_idEquipment', 'storageCode'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('name', 'Descripción').isSortable()
		.add('typeEquipment', 'Tipo Equipo').isSortable().setAlign(null, 'center')
		.add('groupEquipment', 'Grupo Equipo').isSortable().setAlign(null, 'center')
		.add('mark', 'Marca').isSortable().setAlign(null, 'center')
		.add('year', 'Año').isSortable().setAlign(null, 'center')
		.add('hourmeter', 'Horometro').isSortable().setAlign(null, 'center')
		.add('companyName', 'Cliente').isSortable().setSearchKey('company.name')
		.add('storageName', 'Ubicación').isSortable().setSearchKey('storage.name')
		.add('typeArticle', 'Tipo Artículo').isSortable().setAlign(null, 'center')
		.add('groupArticle', 'Grupo Artículo').isSortable().setAlign(null, 'center')
		.add('isValid', '¿Esta Activo?').isSortable().setAlign(null, 'center')
	)

	// Aplicar un reordenamiento a los campos.
	bm.rearrangeFields(fields, [
		'code', 'name', 'typeEquipment', 'groupEquipment', 'mark', 'year', 'hourmeter', 'companyName', 'storageName', 'typeArticle', 'groupArticle', 'isValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('companyName')
		.add('storageName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mark: (v: string) => {
			return v || AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		company: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			const company = Store.getters.getStoredCompanyById(storage?._idCompany)
			return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		storage: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			return storage ? `${ storage.code } - ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedEquipmentsResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Mostrar unicamemte los campos que queremos en la tabla.
	bm.showFields(fields, ['code', 'name'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().showDetailsButton()
		.add('name', 'Descripción').isSortable()
	)

	// Aplicar un reordenamiento a los campos.
	bm.rearrangeFields(fields, [
		'code', 'name'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('storageName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		storage: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			return storage ? `${ storage.code } - ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Tipo Equipo']: ({ typeEquipment }) => {
			return typeEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Grupo Equipo']: ({ groupEquipment }) => {
			return groupEquipment || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Marca']: ({ mark }) => {
			return mark || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Año']: ({ year }) => {
			return year || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Horómetro']: ({ hourmeter }) => {
			return hourmeter || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Cliente']: ({ _idStorage }) => {
			const storage = Store.getters.getStoredStorageById(_idStorage)
			const company = Store.getters.getStoredCompanyById(storage?._idCompany)
			return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Ubicación']: ({ _idStorage }) => {
			const storage = Store.getters.getStoredStorageById(_idStorage)
			return storage ? `${ storage.code } - ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Tipo Artículo']: ({ typeArticle }) => {
			return typeArticle
		},
		['Grupo Artículo']: ({ groupArticle }) => {
			return groupArticle
		},
		['¿Esta Activo?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseRolesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idRole', 'permissions'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('name', 'Nombre').isSortable()
	)

	if (Config.entries.removeRootRole) {
		// Eliminar los items que no queremos mostrar en la tabla.
		bm.removeItems(items, [
			{ field: 'name', value: 'Root' }
		])
	}

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedRolesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idRole', 'permissions'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('name', 'Nombre').isSortable().showDetailsButton()
	)

	if (Config.entries.removeRootRole) {
		// Eliminar los items que no queremos mostrar en la tabla.
		bm.removeItems(items, [
			{ field: 'name', value: 'Root' }
		])
	}

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseWorkAreasResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idWorkArea', '_idLeader'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('name', 'Nombre').isSortable()
		.add('leadername', 'Líder').isSortable().setAlign(null, 'center').setSearchKey('leader.name')
		.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center').setStyleTh({ width: '100px' })
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, ['name', 'leadername', 'isValid'])

	if (Config.entries.removeRootWorkArea) {
		// Eliminar los items que no queremos mostrar en la tabla.
		bm.removeItems(items, [
			{ field: 'name', value: 'Root' }
		])
	}

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('leadername')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		leader: (_: void, item: any) => {
			const { _idLeader } = item
			const user = Store.getters.getStoredUserById(_idLeader)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedWorkAreasResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idWorkArea', '_idLeader', 'isValid'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('name', 'Nombre').isSortable().showDetailsButton()
		.add('leadername', 'Líder').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, ['name'])

	if (Config.entries.removeRootWorkArea) {
		// Eliminar los items que no queremos mostrar en la tabla.
		bm.removeItems(items, [
			{ field: 'name', value: 'Root' }
		])
	}

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('leadername')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		leader: (_: void, item: any) => {
			const { _idLeader } = item
			const user = Store.getters.getStoredUserById(_idLeader)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Lider']: ({ _idLeader }) => {
			const user = Store.getters.getStoredUserById(_idLeader)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['¿Esta Activo?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}